<template>
  <form @submit.prevent="onSaveEngineer" class="relative">
    <div
      v-show="viewState == 'Loading'"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-20"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>
    <!-- Agent -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm">Agent </label>
      <div
        class="fixed inset-0 overflow-hidden z-10"
        v-if="contactSelectOpen"
        @click="contactSelectOpen = false"
      ></div>
      <div class="relative mx-3">
        <div class="relative -mx-3">
          <input
            readonly
            @click="openSearchContact"
            v-model="contactName"
            :class="[
              'w-full bge-input py-2 pl-4 pr-10 rounded cursor-pointer',
              { 'bge-input-disabled': formMode == 'Edit' },
            ]"
            type="text"
          />
          <button
            v-if="contactName && formMode == 'Create'"
            name="Clear Agent"
            @click="clearContact"
            class="m-1 z-10 text-red-500 hover:bg-red-200 rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-150 ease-in-out"
          >
            <svg
              class="stroke-current h-5 w-5"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M18 6L6 18M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div
          v-if="contactSelectOpen"
          class="z-10 absolute w-full max-h-5vh bg-white shadow-lg rounded border border-gray-300 top-0 mt-5 p-2"
        >
          <div class="relative flex flex-col">
            <input
              ref="tbSupplierSearch"
              type="text"
              v-model="contactSearchTerm"
              @keydown="searchContact"
              class="bge-input bge-input-spacing rounded"
              placeholder="Agent name (enter 3 letters or more to search)"
            />
            <div class="flex flex-col overflow-y-scroll bg-white mt-2">
              <template v-for="result in contactResults">
                <button
                  type="button"
                  :key="result.contact_id"
                  @click="selectContact(result)"
                  class="hover:bg-orange-200 flex items-center py-2 transition duration-100 focus:bg-orange-200 text-left px-2"
                >
                  <span class="ml-1">{{ result.name }}</span>
                </button>
              </template>
              <p class="mx-auto py-4 px-8" v-if="contactResults == ''">
                No Agents match your search
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Agent -->

    <!-- First Name -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >First Name<span class="text-red-500">*</span></label
      >
      <input
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        required
        v-model="editedEngineer.firstname"
      />
    </div>
    <!-- /First Name -->

    <!-- Last Name -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Last Name<span class="text-red-500">*</span></label
      >
      <input
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        required
        v-model="editedEngineer.lastname"
      />
    </div>
    <!-- /Last Name -->

    <!-- Email -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Email<span class="text-red-500">*</span></label
      >
      <input
        type="email"
        :class="[
          'w-full bge-input bge-input-spacing',
          { 'bge-input-disabled': formMode == 'Edit' },
          { rounded: !validationErrors.email },
          { 'rounded-t': validationErrors.email },
        ]"
        required
        v-model="editedEngineer.email"
        :disabled="formMode == 'Edit'"
      />
      <span
        v-if="validationErrors.email"
        class="block bg-orange-200 rounded-b border border-t-0 border-orange-400 py-1 px-2 text-orange-700 text-sm"
        >{{ validationErrors.email }}</span
      >
    </div>
    <!-- /Email -->

    <!-- Username -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Username<span class="text-red-500">*</span></label
      >
      <input
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        required
        v-model="editedEngineer.username"
      />
    </div>
    <!-- /Username -->

    <!-- Password -->
    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Password<span v-if="formMode == 'Create'" class="text-red-500"
          >*</span
        ></label
      >
      <input
        type="password"
        class="w-full bge-input bge-input-spacing rounded"
        :required="formMode == 'Create'"
        v-model="editedEngineer.password"
      />
    </div>
    <!-- /Password -->

    <div class="mb-2 w-full">
      <label class="uppercase font-semibold text-sm"
        >Allowed Access Levels</label
      >
      <div
        :class="[
          'border bg-gray-100 border-gray-400 p-4 space-y-2',
          { rounded: !validationErrors.sw900_access_levels },
          { 'rounded-t': validationErrors.sw900_access_levels },
        ]"
      >
        <Toggle
          label="Service"
          labelClasses="uppercase font-semibold text-sm"
          :checked="accessLevels.service"
          @value-changed="(value) => (accessLevels.service = value)"
        />
        <Toggle
          label="Chargeable"
          labelClasses="uppercase font-semibold text-sm"
          :checked="accessLevels.chargeable"
          @value-changed="(value) => (accessLevels.chargeable = value)"
        />
        <Toggle
          label="Reserved"
          labelClasses="uppercase font-semibold text-sm"
          :checked="accessLevels.reserved"
          @value-changed="(value) => (accessLevels.reserved = value)"
        />
      </div>

      <span
        v-if="validationErrors.sw900_access_levels"
        class="block bg-orange-200 rounded-b border border-t-0 border-orange-400 py-1 px-2 text-orange-700 text-sm"
        >{{ validationErrors.sw900_access_levels }}</span
      >
    </div>

    <div class="flex justify-end mt-5">
      <button
        type="submit"
        class="relative overflow-hidden bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <div class="flex items-center">
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
            />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Save Engineer</span>
        </div>
        <div
          class="absolute inset-0 flex justify-center items-center bg-green-200 hover:bg-green-300"
          v-if="viewState == 'Loading'"
        >
          <Spinner class="" :color="'text-black'" :size="6" />
        </div>
      </button>
    </div>
    <!-- <div class="relative flex justify-end mt-5">
      <div v-show="!isBusy" class="opacity-50 bg-white absolute inset-0"></div>
      <button
        :disabled="isBusy"
        type="submit"
        class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
    </div> -->
  </form>
</template>

<script>
const Spinner = () => import("@/components/Spinner.vue");
const Notification = () => import("@/components/Notification.vue");
const Toggle = () => import("@/components/Toggle.vue");
const Datepicker = () => import("vuejs-datepicker");
import _ from "lodash";

export default {
  name: "CreateEditEngineer",
  components: {
    Spinner,
    Toggle,
    Datepicker,
  },
  props: {
    engineer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formMode: "Create",
      editedEngineer: {},
      contactName: "",
      contactSearchTerm: "",
      contactResults: [],
      contactSelectOpen: false,
      viewState: "Idle",
      accessLevels: {
        service: false,
        chargeable: false,
        reserved: false,
      },
      validationErrors: {},
    };
  },
  created() {
    this.editedEngineer = { ...this.engineer };
    console.log(this.editedEngineer);
    if (this.editedEngineer.user_id) {
      this.formMode = "Edit";
      this.editedEngineer.sw900_access_levels.forEach((level) => {
        this.accessLevels[level] = true;
      });
      this.contactName = this.editedEngineer.org_name;
    }
  },
  methods: {
    async onSaveEngineer() {
      try {
        this.viewState = "Loading";
        this.editedEngineer.sw900_access_levels = [];
        Object.entries(this.accessLevels).forEach(([key, value]) => {
          if (value) {
            this.editedEngineer.sw900_access_levels.push(key);
          }
        });
        if (this.editedEngineer.user_id) {
          let response = await this.EngineerService.updateEngineer(
            this.editedEngineer
          );
        } else {
          let response = await this.EngineerService.createEngineer(
            this.editedEngineer
          );
        }

        this.$emit("onSaveEngineer");

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              { props: { title: "Success", close: onClose } },
              "Engineer Updated Successfully"
            );
          },
          { position: "top-right" }
        );
      } catch (error) {
        console.error(error);
        if (error.data.message) {
          this.processValidationErrors(error.data.message);
        }
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              { props: { type: "danger", title: "Error", close: onClose } },
              `Error while creating engineer`
            );
          },
          { position: "top-right" }
        );
      }
      this.viewState = "Idle";
    },
    processValidationErrors: function(validationErrors) {
      this.validationErrors = {};
      validationErrors.map((e) => {
        const error = e.replaceAll(/"/g, "");
        const parts = error.split(" ");
        const field = parts[0];
        let message = parts.slice(1).join(" ");
        message = message.substring(0, 1).toUpperCase() + message.substring(1);
        this.validationErrors[field] = message;
      });
    },
    openSearchContact: function() {
      if (this.formMode == "Edit") return;
      this.contactSearchTerm = "";
      this.contactResults = [];
      this.contactSelectOpen = true;
      this.$nextTick(function() {
        this.$refs.tbSupplierSearch.focus();
      });
    },
    searchContact: _.debounce(async function() {
      this.contactResults = [];
      if (this.contactSearchTerm != "" && this.contactSearchTerm.length >= 3) {
        let results = await this.ContactService.searchContact(
          this.contactSearchTerm
        );
        this.contactResults = results.data;
      }
    }, 500),
    selectContact: function(contact) {
      this.editedEngineer.boston_contact_id = contact.contact_id;
      this.contactName = contact.name;
      this.contactSelectOpen = false;
    },
    clearContact: function() {
      this.editedEngineer.contact_id = "";
      this.contactName = "";
      this.$forceUpdate();
    },
  },
};
</script>
