<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="iV Logins" backTo="tools">
      <button
        @click="showingCreateEditEngineer = true"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Create Engineer</span>
      </button>
    </page-header>

    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md">
        <Table
          v-if="viewState == 'Idle'"
          :columns="columns"
          :data="engineers"
          :totalRecords="paginationOptions.totalRecords"
          :hasPagination="true"
          :currentPage="paginationOptions.currentPage"
          :totalPages="paginationOptions.totalPages"
          :initialSortOptions="sortOptions"
          @onSortChanged="onTableSortChanged"
          @onPageChanged="onTablePageChanged"
        >
          <template v-slot:name="props">
            <p>{{ `${props.row.firstname} ${props.row.lastname}` }}</p>
          </template>
          <template v-slot:access_levels="props">
            <div class="flex items-center space-x-2">
              <template v-for="level in props.row.sw900_access_levels">
                <p
                  :key="level"
                  class="bg-gray-300 rounded leading-none px-2 py-1"
                >
                  {{ level }}
                </p>
              </template>
            </div>
          </template>
          <template v-slot:actions="props">
            <button
              @click="editEngineer(props.row)"
              class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
            >
              <svg
                class="stroke-current h-5 w-5"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path
                  d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                />
              </svg>
            </button>
          </template>
        </Table>
        <div v-show="viewState == 'Loading'">
          <spinner class="mx-auto my-4" :color="'text-black'" :size="8" />
        </div>

        <div v-if="error">
          <div
            class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
          >
            <p>Couldn't load engineers {{ error ? `(${error})` : `` }}</p>
          </div>
        </div>
      </div>
    </main>
    <portal to="overlay-outlet">
      <Panel
        :showing="showingCreateEditEngineer"
        @close="closePanel"
        :title="engineerToEdit.user_id ? 'Edit Engineer' : 'Create Engineer'"
      >
        <!-- <CreateIvyActivation @onActivationComplete="onActivationComplete" /> -->
        <CreateEditEngineer
          :engineer="engineerToEdit"
          @onSaveEngineer="onEngineerSaved"
        />
        <!-- @complete="" -->
      </Panel>
    </portal>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Table from "@/components/Table.vue";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";
import CreateEditEngineer from "@/components/Ivy/CreateEditEngineer.vue";

export default {
  name: "EngineerAccess",
  components: {
    PageHeader,
    Table,
    Panel,
    Spinner,
    CreateEditEngineer,
  },
  data() {
    return {
      viewState: "Idle",
      showingCreateEditEngineer: false,
      engineerToEdit: {},
      engineers: [],
      error: null,
      columns: [
        { title: "Name", field: "name", type: "custom" },
        { title: "Agent", field: "org_name", selector: (row) => row.org_name },
        {
          title: "Access Levels",
          field: "access_levels",
          type: "custom",
        },
        {
          field: "actions",
          type: "custom",
          widthClass: "w-8",
          sortable: false,
        },
      ],
      paginationOptions: {
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0,
      },
      sortOptions: {
        field: "org_name",
        isAsc: false,
        pageSize: 50,
      },
    };
  },
  async mounted() {
    await this.getEngineers();
  },
  methods: {
    getEngineers: async function() {
      this.viewState = "Loading";
      try {
        let result = await this.EngineerService.getEngineers({
          ...this.paginationOptions,
          ...this.sortOptions,
        });
        this.engineers = result.data.filter((eng) => {
          return eng.sw900_access;
        });
        this.paginationOptions.totalRecords = result.count;
        this.paginationOptions.totalPages = result.page_count;
        this.viewState = "Idle";
      } catch (error) {
        console.log(error);
        this.viewState = "Error";
        this.error = error;
      }
    },
    onTableSortChanged: async function(sortOptions) {
      console.log(
        "[EngineerAccess] onTableSortChanged: Changed Sort: ",
        sortOptions
      );
      this.sortOptions = sortOptions;
      await this.getEngineers();
    },
    onTablePageChanged: async function(direction) {
      console.log(
        "[EngineerAccess] onTablePageChanged: Page changed in the following direction: ",
        direction
      );
      this.paginationOptions.currentPage =
        this.paginationOptions.currentPage + direction;
      await this.getEngineers();
    },
    editEngineer: function(engineer) {
      this.engineerToEdit = engineer;
      this.showingCreateEditEngineer = true;
    },
    closePanel: function() {
      this.showingCreateEditEngineer = false;
      this.engineerToEdit = {};
    },
    onEngineerSaved: async function() {
      this.closePanel();
      await this.getEngineers();
    },
  },
};
</script>
